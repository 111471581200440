import React, { Component } from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import { Link } from "gatsby"
import Getstarted from '../components/getstarted.js'


class Header extends Component{
  
  state = {
      show: false,
      setShow:  false
  }
  handleClose = () =>{
    this.setState({show: false})
  }
  handleShow = () =>{
    this.setState({show: true})
  }

  render()
  {
    return(
      <div style={{backgroundColor:"#ffffff"}}>
       <div className="container">
        <Navbar expand="lg" className="pl-0 pr-0">
          <Navbar.Brand><Link to="/"><img src="/images/logo.svg" style={{ width: `200px` }} alt="crampete logo"/></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-0">
          <NavDropdown title="CRAM CLASS" id="basic-nav-dropdown">
            <Link to="/cramclass" className="dropdown-item">All Courses</Link>
            <Link to="/courses/full-stack-web-development-online-course" className="dropdown-item">Full Stack Web Development</Link>
            <Link to="/courses/data-science-online-course" className="dropdown-item">Data Science</Link>
            <Link to="/courses/digital-marketing-offline-courses" className="dropdown-item">Digital Marketing</Link>
            <Link to="/courses/front-end-online-course" className="dropdown-item">Front End Development</Link>
            <Link to="/courses/back-end-online-course" className="dropdown-item">Back End Development</Link>
          </NavDropdown>
          <Link to="/cramcode" className="nav-link">CRAM CODE</Link>
          <Link to="/reviews" className="nav-link">REVIEWS</Link>
          <NavDropdown title="RESOURCES" id="basic-nav-dropdown">
            <Link to="/blogs" className="dropdown-item">Blogs</Link>
            {/* <Link to="/reviews" className="dropdown-item">Practice</Link> */}
            <Link to="/guides" className="dropdown-item">Guides</Link>
            <Link to="/videos" className="dropdown-item">Video</Link>
            <Link to="/webinars" className="dropdown-item">Webinars</Link>
            <Link to="/contests" className="dropdown-item">Contests</Link>
          </NavDropdown>
          </Nav>
          <Nav className="ml-auto mr-0">
          <Button onClick={this.handleShow} >Get Started</Button>
          </Nav>
          </Navbar.Collapse>
          </Navbar>
       </div>
       <Getstarted showPop = {this.state.show} hidePop={this.handleClose}/>
       </div>

    )
  }
}

export default Header


