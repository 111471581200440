import React, { Component } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"

class Getstarted extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      errors: {},
      course: [
        { title: "Data Science", id: "3a99415f-3f90-55e7-96d8-605a8d9d1e6a" },
        {
          title: "Full Stack Development",
          id: "e5bd49f8-1e98-58a8-acb2-9a0e5dd0ec25",
        },
        {
          title: "Digital Marketing",
          id: "060f004f-77e9-58f8-887a-7e481063b567",
        },
      ],
      success: "",
      submitState: false,
    }
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true

    //Name
    if (!fields["name"]) {
      formIsValid = false
      errors["name"] = "Please enter your name"
    }

    //course
    if (!fields["course"]) {
      formIsValid = false
      errors["course"] = "Please select the course"
    }

    //Mobile
    if (!fields["mobile"]) {
      formIsValid = false
      errors["mobile"] = "Please enter your mobile number"
    }
    if (typeof fields["mobile"] !== "undefined") {
      if (!fields["mobile"].match(/^[0-9]+$/)) {
        formIsValid = false
        errors["mobile"] = "Please enter valid mobile number"
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false
      errors["email"] = "Please enter your email"
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@")
      let lastDotPos = fields["email"].lastIndexOf(".")

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors["email"] = "Please enter valid email"
      }
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.handleValidation()
    if (this.handleValidation()) {
      this.setState({ submitState: true })

      //Post Leads
      let data = {
        Name: this.state.fields["name"],
        EmailID: this.state.fields["email"],
        MobileNumber: this.state.fields["mobile"],
        Course: [this.state.fields["course"]],
        EmailTemplate: "CourseTemplate",
        Source: "getstart",
      }

      const submitData = await fetch(
        "https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/leads/create-lead",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )

      let resp = await submitData.json()
      if (resp.success) {
        this.setState({ success: true })
      } else {
        this.setState({ success: false })
      }
      setTimeout(
        () => this.setState({ submitState: false, fields: {}, success: false }),
        5000
      )
      document.getElementById("get-start-form").reset()
    }
  }

  render() {
    // console.log(this.state)

    const rspSuccess = (
      <Alert
        className={`${this.state.success === true ? "d-block" : "d-none"}`}
        variant="success"
        style={{ fontSize: "14px", textAlign: "center" }}
      >
        Thank you, your enquiry has been submitted !
      </Alert>
    )
    const rspFail = (
      <Alert
        className={`${this.state.success === false ? "d-block" : "d-none"}`}
        variant="danger"
        style={{ fontSize: "14px", textAlign: "center" }}
      >
        Something went wrong please try again later!
      </Alert>
    )
    const submitMsg = this.state.success ? rspSuccess : rspFail

    return (
      <Modal
        className="getStartedForm"
        centered
        show={this.props.showPop}
        onHide={this.props.hidePop}
        backdrop="true"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center font-weight-bold mt-3 mb-3">
            Enroll in tech courses designed for industry requirements to make
            you an in-demand pro!
          </div>
          <Form
            id="get-start-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="post"
          >
            {this.state.submitState ? submitMsg : ""}
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={this.handleChange.bind(this, "name")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["name"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={this.handleChange.bind(this, "email")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["email"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                name="mobile"
                onChange={this.handleChange.bind(this, "mobile")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["mobile"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="course">
              <Form.Label>Course</Form.Label>

              <Form.Control
                name="course"
                as="select"
                onChange={this.handleChange.bind(this, "course")}
              >
                <option disabled selected>
                  -- Select --
                </option>
                {this.state.course.map(item => {
                  return <option value={item.id}>{item.title}</option>
                })}
              </Form.Control>
              <Form.Text className="text-danger">
                {this.state.errors["course"]}
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default Getstarted
