import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/Seo"

export default ({
  children,
  title,
  description,
  keywords,
  image,
  url,
  author,
}) => {
  title = title || ""
  author = author || ""
  url = url || ""
  image = image || ""
  keywords = keywords || ""
  description = description || ""

  return (
    <div>
      <Seo
        title={title}
        description={description}
        keywords={keywords}
        image={image}
        url={url}
        author={author}
      />
      <Header />
      {children}
      <Footer />
    </div>
  )
}
